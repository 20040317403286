<template>
  <div class="hero-body">
    <p class="current-player-label">目前轮到：{{ gameData.players[gameData.current].name }}</p>
    <hr/>

    <div v-for="(player, index) in gameData.players" :key="index">
      <div class="box" v-if="index != me" v-bind:class="isCurrentPlayer(index)">
        <div class="columns is-mobile">
          <div class="column">对手: {{ player.name }}</div>
        </div>
        <div class="columns is-mobile">
          <div v-for="(number, n_index) in player.scores" :key="n_index" class="column">
            <button 
              class="button is-primary is-fullwidth is-light"
              :disabled="player.scores[n_index] == 0"
              @click="addNumber(index, n_index)">
              {{number}}
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr/>
    
    <div class="box" v-bind:class="isCurrentPlayer(me)">
      <div class="columns is-mobile">
        <div class="column">我: {{ gameData.players[me].name }}</div>
      </div>
      <div class="columns is-mobile">
        <div v-for="(number, n_index) in gameData.players[me].scores" :key="n_index" class="column">
          <button 
              class="button is-primary is-fullwidth" 
              :class="isNumberSelected(n_index)"
              :disabled="gameData.players[me].scores[n_index] == 0"
              @click="selectNumber(me, n_index)">
              {{number}}
            </button>
        </div>
      </div>
    </div>
    <p class="winner-label" v-if="winner">恭喜 <span>{{ winner }}</span> 获得胜利！</p>
    <button class="button is-success is-medium btn-restart" @click="resetGame()">重新开始</button>
  </div>
</template>

<script>
import game from '../repository'

export default {
  name: 'Board',
  data() {
    return {
      gameData: {
        players: [
          {name: "Player1", scores: [1, 1, 1]},
          {name: "Player2", scores: [1, 1, 1]},
          {name: "Player3", scores: [1, 1, 1]}
        ],
        current: 0,
      },
      me: 0,
      selected_idx: -1,
      winner: null
    }
  },

  mounted() {
    this.gameObject = game.gameObject.attributes.data
    this.gameIdx = this.$route.params.gameId
    this.me = this.$route.params.userId
    this.gameData = this.gameObject[this.gameIdx]

    game.bindGameObject("jiajiale", data => {
      this.gameObject = data.attributes.data
      this.gameData = this.gameObject[this.gameIdx]
      this.winner = null
      this.checkWinner()
    })
  },

  methods: {
    isCurrentPlayer(index) {
      if (index == this.gameData.current) {
        return "current-player"
      }
      else {
        return ""
      }
    },

    isNumberSelected(nIndex) {
      if (this.selected_idx >= 0) {
        if (this.selected_idx == nIndex) {
          return ""
        }
        else {
          return "is-light"
        }
      }
      else {
        return "is-light"
      }
    },

    isDisabled(pIndex, nIndex) {
      return this.scores[pIndex][nIndex] == 0
    },

    selectNumber(pIndex, nIndex) {
      if (this.gameData.current != pIndex) {
        alert("抱歉! 还没轮到你。")
      }
      else {
        this.selected_idx = nIndex
        console.log(this.selected_idx)
      }
    },

    addNumber(pIndex, nIndex) {
      if (this.gameData.current != this.me) {
        alert("抱歉! 还没轮到你。")
      }
      else if (this.selected_idx == -1) {
        alert("请先选一个自己的数字")
      } else {
        var num1 = this.gameData.players[this.me].scores[this.selected_idx]
        var num2 = this.gameData.players[pIndex].scores[nIndex]
        var result = (num1 + num2) % 10
        this.gameData.players[this.me].scores[this.selected_idx] = result
        
        this.selected_idx = -1
        this.gameData.current = (this.gameData.current + 1) % this.gameData.players.length

        // Update Database
        var gameData = JSON.parse(JSON.stringify(this.gameData))
        this.gameObject[this.gameIdx] = gameData
        game.updateObject(this.gameObject)
      } 
    },

    sumArray(arr) {
      var sum = 0
      for (var i=0; i<arr.length; i++) {
        sum += arr[i]
      }
      return sum
    },

    checkWinner() {
      for (var i=0; i<this.gameData.players.length; i++) {
        if (this.sumArray(this.gameData.players[i].scores) == 0) {
          this.winner = this.gameData.players[i].name
        }
      }
    },

    resetGame() {
      this.gameData.current = 0,
      this.selected_idx = -1
      for (var i=0; i<this.gameData.players.length; i++) {
        for (var j=0; j<this.gameData.players[i].scores.length; j++) {
          this.gameData.players[i].scores[j] = 1;
        }
      }
      this.winner = null

      console.log(this.gameData)
      // Update Database
      var gameData = JSON.parse(JSON.stringify(this.gameData))
      this.gameObject[this.gameIdx] = gameData
      game.updateObject(this.gameObject)
    }
  }
}
</script>

<style scoped>
.hero-body {
  padding-top: 10px;
}

.current-player-label {
  margin-bottom: 10px;
}

.current-player {
  background-color: #ffe6cc;
}

.btn-restart {
  float: right;
  margin-top: 15px;
}

.winner-label {
  font-size: 22px;
  text-align: center;
}
</style>
