<template>
  <div>
    <h1>选择一个游戏入口</h1>
    <div class="notification">
      <router-link to="/login/0" class="button is-primary is-light is-fullwidth">2人2数局</router-link>
      <router-link to="/login/1" class="button is-primary is-light is-fullwidth">3人2数局</router-link>
      <router-link to="/login/2" class="button is-primary is-light is-fullwidth">2人3数局</router-link>
      <router-link to="/login/3" class="button is-primary is-light is-fullwidth">3人3数局</router-link>
    </div>
  </div>

</template>

<script>
import game from '../repository'

export default {
  name: 'Home',
  data() {
    return {
      
    }
  },

  created() {
    var game_data0 = {
      players: [
        {name: "player1", scores: [1, 1]},
        {name: "player2", scores: [1, 1]}
      ],
      current: 0,
      selected_idx: -1
    }

    var game_data1 = {
      players: [
        {name: "player1", scores: [1, 1]},
        {name: "player2", scores: [1, 1]},
        {name: "player3", scores: [1, 1]}
      ],
      current: 0,
      selected_idx: -1
    }

    var game_data2 = {
      players: [
        {name: "player1", scores: [1, 1, 1]},
        {name: "player2", scores: [1, 1, 1]}
      ],
      current: 0,
      selected_idx: -1
    }

    var game_data3 = {
      players: [
        {name: "player1", scores: [1, 1, 1]},
        {name: "player2", scores: [1, 1, 1]},
        {name: "player3", scores: [1, 1, 1]}
      ],
      current: 0,
      selected_idx: -1
    }

    game.loadGameObject("jiajiale", [game_data0, game_data1, game_data2, game_data3])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  padding: 10px;
  text-align: left;
  font-size: 1.12em;
}
a.button {
  margin-bottom: 7px;
}
</style>
