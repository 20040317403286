<template>
<section class="">
  <div class="hero-body">
    <!-- <p class="title">
      Info hero
    </p> -->
    <p class="subtitle">
      请选择一个座位，并输入姓名
    </p>
    <ul>
      <li v-for="(item, index) in gameData.players" :key="index">
        <button
          class="button is-primary is-fullwidth btn"
          v-bind:class="isPlayerSelected(index)"
          @click="selectPlayer(index)">
          Player {{index + 1}}
        </button>
      </li>
    </ul>
    <hr/>

    <p class="left">请输入名字</p>
    <input class="input" type="text" placeholder="我的名字" v-model="myName"><br/>
    <b-button type="is-primary is-light" class="btn" expanded @click="startGame()">进入游戏</b-button><br/>
  </div>
</section>
</template>

<script>
import game from '../repository'

export default {
  name: 'Login',
  data() {
    return {
      gameIdx:  0,
      gameData: {
        players: [
          {name: "player1", scores: [1, 1, 1]},
          {name: "player2", scores: [1, 1, 1]},
          {name: "player3", scores: [1, 1, 1]}
        ],
        current: 0,
        selected_idx: -1
      },
      me: -1,
      myName: ""
    }
  },

  mounted() {
    this.gameObject = game.gameObject.attributes.data
    this.gameIdx = this.$route.params.gameId
    this.gameData = this.gameObject[this.gameIdx]

    game.bindGameObject("jiajiale", data => {
      this.gameObject = data.attributes.data
      this.gameData = this.gameObject[this.gameIdx]
    })
  },

  methods: {
    isPlayerSelected(index) {
      if (index == this.me) {
        return ""
      }
      else {
        return "is-light"
      }
    },

    selectPlayer(index) {
      this.me = index
      this.myName = this.gameData.players[index].name
    },

    getPlayerName() {
      if (this.me < 0) {
        return ""
      }
      else {
        return this.gameData.players[this.me].name
      }
    },

    startGame() {
      var gameData = JSON.parse(JSON.stringify(this.gameData))
      this.gameObject[this.gameIdx] = gameData
      gameData.players[this.me].name = this.myName
      game.updateObject(this.gameObject)
      this.$router.push('/board/' + this.gameIdx + '/' + this.me)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p.left {
  float: left;
}

.btn {
  margin-top: 8px;
}
</style>
